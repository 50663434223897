@each $font in Ubuntu-Bold,Ubuntu-Regular,Ubuntu-Medium, Ubuntu-Light{
  @font-face {
    font-family:"#{$font}";
    src: url("fonts/#{$font}.ttf") ;
  }
}

@each $font in MuseoSansCyrl,MuseoSansCyrl_0,MuseoSansCyrl_2, MuseoSansCyrl_3,MuseoSansCyrl_1, SansRoundedC, rouble{
  @font-face {
    font-family:"#{$font}";
    src: url("fonts/#{$font}.otf") ;
  }
}


