/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$icon1-name: 'icon1';
$icon1-x: 200px;
$icon1-y: 0px;
$icon1-offset-x: -200px;
$icon1-offset-y: 0px;
$icon1-width: 126px;
$icon1-height: 112px;
$icon1-total-width: 326px;
$icon1-total-height: 298px;
$icon1-image: 'images/sprite.png';
$icon1: (200px, 0px, -200px, 0px, 126px, 112px, 326px, 298px, 'images/sprite.png', 'icon1', );
$icon2-name: 'icon2';
$icon2-x: 200px;
$icon2-y: 112px;
$icon2-offset-x: -200px;
$icon2-offset-y: -112px;
$icon2-width: 118px;
$icon2-height: 82px;
$icon2-total-width: 326px;
$icon2-total-height: 298px;
$icon2-image: 'images/sprite.png';
$icon2: (200px, 112px, -200px, -112px, 118px, 82px, 326px, 298px, 'images/sprite.png', 'icon2', );
$icon3-name: 'icon3';
$icon3-x: 0px;
$icon3-y: 0px;
$icon3-offset-x: 0px;
$icon3-offset-y: 0px;
$icon3-width: 200px;
$icon3-height: 200px;
$icon3-total-width: 326px;
$icon3-total-height: 298px;
$icon3-image: 'images/sprite.png';
$icon3: (0px, 0px, 0px, 0px, 200px, 200px, 326px, 298px, 'images/sprite.png', 'icon3', );
$icon4-name: 'icon4';
$icon4-x: 0px;
$icon4-y: 200px;
$icon4-offset-x: 0px;
$icon4-offset-y: -200px;
$icon4-width: 102px;
$icon4-height: 98px;
$icon4-total-width: 326px;
$icon4-total-height: 298px;
$icon4-image: 'images/sprite.png';
$icon4: (0px, 200px, 0px, -200px, 102px, 98px, 326px, 298px, 'images/sprite.png', 'icon4', );
$moto-name: 'moto';
$moto-x: 102px;
$moto-y: 200px;
$moto-offset-x: -102px;
$moto-offset-y: -200px;
$moto-width: 52px;
$moto-height: 47px;
$moto-total-width: 326px;
$moto-total-height: 298px;
$moto-image: 'images/sprite.png';
$moto: (102px, 200px, -102px, -200px, 52px, 47px, 326px, 298px, 'images/sprite.png', 'moto', );
$spritesheet-width: 326px;
$spritesheet-height: 298px;
$spritesheet-image: 'images/sprite.png';
$spritesheet-sprites: ($icon1, $icon2, $icon3, $icon4, $moto, );
$spritesheet: (326px, 298px, 'images/sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
