/*------------------------------------*\
  $IMPORTS
\*------------------------------------*/
@import "_normalize";
@import "_fonts";
@import "_sprite";


/*------------------------------------*\
  $COLORS
\*------------------------------------*/
$maincolor: #8cddf5;
$buttonBg: rgb(255, 36, 36);
$buttonColor: #ffffff;
$text-color: #303030;

/*------------------------------------*\
  $MIXINS, $PLACEHOLDERS, $FUNCTIONS
\*------------------------------------*/

/*  $FUNCTIONS
  ------------------------------------ */
@function rem($x) {
  @return ($x / 16)+rem;
}

/*!MIXINS */

@mixin square ($width, $height, $color ){
  width:$width;
  height: $height;
  background-color: $color;

}



.icon {
  display: inline-block !important;
}

/*------------------------------------*\
  $TYPOGRAPHY
\*------------------------------------*/
$base-font-size: rem(16);
$base-line-height: 1;
$f-first: "MyriadPro-Light";
$f-second: "Verdana", sans-serif;

html, body {
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-family: $f-first;
  height: 100%;
  min-height: 100%;
}

h1, h2, h3, h4, h5, h6 {

}

/*!COMMON ELEMENT*/

.row{
 width:1180px;
  max-width:1180px;
  height:100%;
  margin: 0 auto;
}

/*!HEADER */

header{
  background:url('images/background.jpg') no-repeat center center;
  height:750px;
  background-size: cover;
}
.topline-logo{
img{
  max-width: none;

}
}
.rub{
  font:rem(16)/1 'rouble';
  display: inline-block;
}
.header-topline{
  padding-top: 40px;
}
.topline-menu__item{
  display:inline-block;
margin-right:30px;
  .fa{
    color:#fff;
    margin-right: 5px;
    font-size:14px;
  }
}


.topline-menu{
  margin-top: 10px;
}
.topline-menu__link{
  color:#fff;
  font: rem(12)/1 'MuseoSansCyrl_1';
  text-transform: uppercase;
}
.topline-phone-square{
  @include square(190px, 40px, rgba(0,0,0,0.35));
  border-radius: 20px 0 20px 0;

display: flex;
  align-items: center;
  justify-content: center;
}

.topline-phone {
  p {
    color: #fff;
    font: rem(16)/1 'MuseoSansCyrl';
    display: inline;
  }
  .strong {
    display: inline;
    font: rem(16)/1 'MuseoSansCyrl_3';
    color: #fff;
  }
  .fa{
    color:#fff;
    margin-right:5px;
  }
}


/*!MAIN CONTENT*/

.section-bouquet{
  padding:80px 0;
}
.bouquet-container{
  padding:35px 0 30px 0;
  border-radius: 20px 0 20px 0;
  border: 2px solid #fff;
  transition: all ease-in-out 0.2s;
  position:relative;
  &:hover{
    border: 2px solid #999999;
    cursor:pointer;
  }
}
.bouquet-container--all{
  @include square(100%, 380px, #ffcc00);
  border: none;
  &:hover{
    border: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.bouquet-container__text{
  position:relative;
  font:rem(30)/1.1 'SansRoundedC';
  color:#fff;
  text-transform: uppercase;
}



.bouquet-container__img{
padding: 0 20px 0 20px;
}
.bouquet-container__hot{
  @include square(90px,60px, #ffcc00);
  position:absolute;
  border-radius: 30px 0 30px 0;
  display:flex;
  justify-content: center;
  flex-direction:column;
  top: 41px;
  left: 30px;
}
.container-hot__text{
  position:relative;
  font:rem(27)/1.1 'SansRoundedC';
  color:#fff;
  text-transform: uppercase;
  text-align: center;
}
.container-hot__text--1{
  font:rem(12)/1.1 'SansRoundedC';
}

.bouquet-container__all{
  position: absolute;
  width: 350px;
  bottom: 0;
  right: -47px;

}

.bouquet-container__price{
  font:rem(36)/1 'MuseoSansCyrl_3';
  color:#303030;
  padding: 35px 0 0 25px;
}
.bouquet-container__info{
  font:rem(15)/1 'MuseoSansCyrl_0';
  color:#999999;
  padding: 7px 0 0 24px;
}


.delivery-container{
  background:url('images/delivery.jpg') no-repeat center center;
height:380px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delivery-header{
  font:rem(85)/1 'SansRoundedC';
  color:#fff;
  text-transform: uppercase;
}

.delivery-price{
  font:rem(40)/1.5 'MuseoSansCyrl_3';
  color:#444444;
  @include square(200px, 60px, #fff);
  border-radius: 30px 0 30px 0;
  text-align: center;

  margin: 30px 0 20px 0;
  .small{
    font:rem(32)/1.5 'MuseoSansCyrl_3';
    display: inline;
  }
  p{
    display: inline;
  }
}
.delivery-info{
  font:rem(22)/1 'SansRoundedC';
  color: #ffffff;}

.delivery-info__text{
    display: inline-block;
    vertical-align: middle;
  }


.delivery-info__icon{
  @include sprite($moto);
  display: inline-block;
  vertical-align: middle;

}

.bouquet-small{
  @include square (100%, 380px, #798399);
  margin: 30px 0 0 0;
  border-radius: 50px 0 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.bouquet-small__img{
  img{
    position: absolute;
    bottom: 0;
    left: -110px;
    max-width: none;

  }
}

.bouquet-large__img{
img{
  position: absolute;
  bottom: 0;
  right: 44px;}
}
.bouquet-large__img--1{
  img{
    position: absolute;
    bottom: 0;
    right: 44px;}
}

.bouquet-small__img--3{
  img {
    width: 391px;
    left:0;
  }
  .bouquet-small__item1{
    width:313px;
    left: 40px;
  }
  .bouquet-small__item2{
    width:214px;
    left: -60px;
    bottom: -30px;
  }
  }


.bouquet-small__text, .bouquet-large__text{

  font:rem(50)/1 'SansRoundedC';
  color:#fff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 30px;
  position:relative;
}

.bouquet-small__price, .bouquet-large__price {
  @include square(150px, 50px, #00c4f5);
  border-radius: 30px 0 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
p {
  font: rem(24)/1 'MuseoSansCyrl_3';
  color: #fff;
}
}

.bouquet-large__price{
  @include square(150px, 50px, #da1450);
}

.bouquet-large__price--1{
  @include square(150px, 50px, #00c4f5);
}



.bouquet-small--1{
  background-color: #b8e475;
}

.bouquet-box{
  width:300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-left:40px;
  align-items: center;
}
.bouquet-large{
  @include square (100%,380px , #f3cedf);
  margin: 30px 0 0 0;
  border-radius: 50px 0 50px 0;
  position: relative;
}

.bouquet-large--1{
  background-color: #aae4f3;
}

.section-steps{
  padding-top:90px;
  text-align: center;
}
.steps-header{
  h2 {
    font: rem(45)/1 'SansRoundedC';
    color: #727e8b;
    text-transform: uppercase;
    padding-bottom: 90px;
  }
}
.steps-circle{
  height: 200px;
  width: 200px;
  border-radius: 100px;
  border:3px solid #49e4ff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.steps-text{
  text-align: center;
  font:rem(16)/1.2 'MuseoSansCyrl_0';
  color:#727e8b;
  padding-top: 30px;
  width:215px;
  margin: 0 auto;
}
.section-bouquet-price{
  padding-top: 90px;
}
.bouquet-price {
  border-radius: 50px 0 50px 0;
  position: relative;
  @include square(224px, 300px, #f7c2c1);
  display:inline-block;
}
.bouquet-price--2 {
  @include square(224px, 300px, #ede6f8);
}
.bouquet-price--3 {
   @include square(224px, 300px, #dde890);
 }
.bouquet-price--4 {
  @include square(224px, 300px, #fdc902);
}

.price {
  font: rem(22)/1 'MuseoSansCyrl_3';
  color: #fff;
  border-radius: 30px 0 30px 0;
  text-align: center;
  @include square(144px, 72px, #da1450);
  position: absolute;
  bottom: 5px;
  left: -30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.bouquet-price{
  img{
    max-width: none;
    z-index: 1;
  }
}
.bouquet-price__img--1{
  position:absolute;
  top: -4px;
  left: -84px;
}
.bouquet-price__img--2{
  position:absolute;
  top: -4px;
  left: -43px;
}
.bouquet-price__img--3{
  position:absolute;
  top:-4px;
  left:-42px;
}
.bouquet-price__img--4{
  position:absolute;
  top: -4px;
  left: -26px;
}

  /* !POP-UP WINDOWS */
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: none;
  }

  .modal-bg {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .content-modal {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  .modal__item {
    width: 990px;
    height: 680px;
    background: #ffffff;
    display: none;
    z-index: 3;
    position: relative;
    margin: 80px auto 0 auto;
    padding: 30px 50px 0 50px;
    &:after{
      content: '';
      display:block;
      width:100%;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 59px;
      background: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0));
    }

  }

  label.error {
    background: red;
  }
